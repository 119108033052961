#myBtn {
    display: none; /* Initially hidden */
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #7F7070;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    font-size: 18px;
}

#myBtn:hover {
    background-color: #555; /* Add a dark-grey background on hover */
}

#myBtn.visible {
    display: block; /* Show button when the class 'visible' is applied */
}
